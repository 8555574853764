import { Skeleton } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MultiStepComponent from "../../../components/MultiStepComponent";
import CrudService from "../../../service/CrudService";

const AICallEdit = ({ id, onFinish }) => {
  const { t } = useTranslation();

  const steps = () => [
    {
      id: "step1",
      name: t("Task Description"),
      form: [
        {
          fieldName: "firstMessage",
          label: t("First message"),
          type: "textarea-mention",
          placeholder: t("Enter the first message to say in the phone call"),
          rows: 2,
          mentionData: [
            { id: "candidateFirstname", display: t("Lead's Firstname") },
            { id: "candidateLastname", display: t("Lead's Lastname") },
            { id: "candidateEmail", display: t("Lead's Email") },
            { id: "candidatePhone", display: t("Lead's Phone") },
            { id: "jobTitle", display: t("Job Title") },
            { id: "jobLocation", display: t("Job Location") },
            { id: "jobApplicationLink", display: t("Job Application Link") },
            { id: "interviewDate", display: t("Interview Date") },
            { id: "interviewTime", display: t("Interview Time") },
            { id: "interviewDateEnd", display: t("Interview Ending Date") },
            { id: "interviewTimeEnd", display: t("Interview Ending Time") },
            {
              id: "interviewMeetingLink",
              display: t("Interview Meeting Link"),
            },
            { id: "calendly_cancel_url", display: t("Calendly Cancel URL") },
            {
              id: "calendly_reschedule_url",
              display: t("Calendly Reschedule URL"),
            },
            { id: "companyName", display: t("Company Name") },
            { id: "companyWebsite", display: t("Company Website") },
            { id: "companyAddress", display: t("Company Address") },
            { id: "applicationStatus", display: t("Application Status") },
            { id: "rejectionReason", display: t("Rejection Reason") },
            { id: "calendarLink", display: t("Calendar Link") },
            { id: "userCalendarLink", display: t("User Calendar Link") },
            { id: "currentDate", display: t("Current Date") },
            { id: "currentTime", display: t("Current Time") },
            { id: "userFirstname", display: t("User Firstname") },
            { id: "userLastname", display: t("User Lastname") },
            { id: "userEmail", display: t("User Email") },
            { id: "userPhone", display: t("User Phone") },
            { id: "cvLink", display: t("CV Submission Link") },
            { id: "surveyLink", display: t("Survey Link") },
            {
              id: "interviewTimeRemaining",
              display: t("Interview Remaining Time"),
            },
          ],
        },

        {
          fieldName: "onEnterCallScript",
          label: t("Describe the task of your call agent"),
          type: "textarea-mention",
          placeholder: t("Enter task description"),
          rows: 6,
          mentionData: [
            { id: "candidateFirstname", display: t("Lead's Firstname") },
            { id: "candidateLastname", display: t("Lead's Lastname") },
            { id: "candidateEmail", display: t("Lead's Email") },
            { id: "candidatePhone", display: t("Lead's Phone") },
            { id: "jobTitle", display: t("Job Title") },
            { id: "jobLocation", display: t("Job Location") },
            { id: "jobApplicationLink", display: t("Job Application Link") },
            { id: "interviewDate", display: t("Interview Date") },
            { id: "interviewTime", display: t("Interview Time") },
            { id: "interviewDateEnd", display: t("Interview Ending Date") },
            { id: "interviewTimeEnd", display: t("Interview Ending Time") },
            {
              id: "interviewMeetingLink",
              display: t("Interview Meeting Link"),
            },
            { id: "calendly_cancel_url", display: t("Calendly Cancel URL") },
            {
              id: "calendly_reschedule_url",
              display: t("Calendly Reschedule URL"),
            },
            { id: "companyName", display: t("Company Name") },
            { id: "companyWebsite", display: t("Company Website") },
            { id: "companyAddress", display: t("Company Address") },
            { id: "applicationStatus", display: t("Application Status") },
            { id: "rejectionReason", display: t("Rejection Reason") },
            { id: "calendarLink", display: t("Calendar Link") },
            { id: "userCalendarLink", display: t("User Calendar Link") },
            { id: "currentDate", display: t("Current Date") },
            { id: "currentTime", display: t("Current Time") },
            { id: "userFirstname", display: t("User Firstname") },
            { id: "userLastname", display: t("User Lastname") },
            { id: "userEmail", display: t("User Email") },
            { id: "userPhone", display: t("User Phone") },
            { id: "cvLink", display: t("CV Submission Link") },
            { id: "surveyLink", display: t("Survey Link") },
            {
              id: "interviewTimeRemaining",
              display: t("Interview Remaining Time"),
            },
          ],
        },
      ],
    },

    {
      id: "step4",
      name: t("Call Configuration"),
      form: [
        {
          fieldName: "aiSpeed",
          label: t("Speed"),
          type: "select",
          options: [
            { value: 0.85, label: t("Slow") },
            { value: 1, label: t("Normal") },
            { value: 1.35, label: t("Fast") },
            { value: 1.6, label: t("Ultra Fast") },
          ],
          placeholder: t("Select the speed of speech"),
        },

        {
          fieldName: "aiLanguage",
          label: t("Language"),
          type: "select",
          options: [
            { value: "en-US", label: t("American English") },
            { value: "en-GB", label: t("British English") },
            { value: "nl", label: t("Dutch") },
            { value: "fr", label: t("French") },
            { value: "de", label: t("German") },
            { value: "it", label: t("Italian") },
            { value: "ja", label: t("Japanese") },
            { value: "pl", label: t("Polish") },
            { value: "pt", label: t("Portuguese") },
            { value: "ru", label: t("Russian") },
            { value: "es", label: t("Spanish") },
            { value: "sv", label: t("Swedish") },
            { value: "tr", label: t("Turkish") },
            { value: "zh", label: t("Chinese") },
          ],
          placeholder: t("Select the language"),
        },
        {
          fieldName: "aiMaxDuration",
          label: t("Maximum Call Duration"),
          type: "inputNumber",
          placeholder: t(
            "Specify the maximum duration of an interview in minutes"
          ),
        },
        {
          fieldName: "aiRecord",
          label: t("Record Call"),
          type: "switch",
          placeholder: t(
            "Specify whether you would like to create a voice recording of your call"
          ),
        },
      ],
    },
  ];

  const [vacancyData, setVacancyData] = useState(null);
  const vidRef = useRef();

  useEffect(() => {
    if (vidRef.current) vidRef.current.play();
  }, [vidRef]);

  useEffect(() => {
    if (!id) return;

    CrudService.getSingle("VacancyStage", id).then((res) => {
      if (!res.data) return;
      setVacancyData(res.data);
    });
  }, [id]);

  if (!vacancyData) return <Skeleton active />;

  return (
    <>
      <MultiStepComponent
        AIEnhancements={true}
        displayUndoRedo
        steps={steps()}
        defaultFormData={{
          ...vacancyData,
        }}
        onFinish={async (formData) => {
          if (!id) return;

          await CrudService.update("VacancyStage", id, {
            ...formData,
          });

          onFinish();
        }}
        onNext={async (formData) => {
          if (!id) return;

          await CrudService.update("VacancyStage", id, {
            ...formData,
          });
        }}
        buttomLineWrapperClass=" w-full bg-white dark:bg-gray-900 rounded-t-sm pl-[20px] pt-[20px] pb-[20px] pr-[80px] right-0	bottom-0"
        buttomLineInnerClass="items-end flex flex-col"
      />
    </>
  );
};

export default AICallEdit;
