import {
  Alert,
  Divider,
  InputNumber,
  Skeleton,
  Space,
  Tooltip,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaDeleteLeft } from "react-icons/fa6";
import { GrInfo } from "react-icons/gr";
import { Mention, MentionsInput } from "react-mentions";
import CrudService from "../../../service/CrudService";
import classNamesBody from "../Message/body.module.css";
import classNames from "../Message/example.module.css";
import { removeAtIndex, replaceAtIndex } from "../PartnerSettings";

const DelayedAutomations = ({ stageId }) => {
  const { t } = useTranslation();
  const [softValue, setSoftValue] = useState(null);

  useEffect(() => {
    if (!stageId) return;

    CrudService.getSingle("VacancyStage", stageId).then(({ data }) => {
      setSoftValue(data);
    });
  }, [stageId]);

  const handleUpdate = useCallback(async () => {
    if (!softValue) return;
    if (!stageId) return;

    CrudService.update("VacancyStage", stageId, softValue).then(() => {
      message.success(t("Saved"));
    });
  }, [stageId, softValue, t]);

  if (!softValue) return <Skeleton active />;
  return (
    <>
      <br />
      {!localStorage.closedAutomatedStageMessageInfo && (
        <Alert
          type="info"
          className="my-1"
          message={t(
            "You have the option to set up a series of automated messages for leads who remain inactive at this stage. Each message will be dispatched if a lead's status remains unchanged in this particular stage for a duration exceeding the time limit you specify in hours. This feature is to enable consistent communication and engagement with leads throughout the marketing pipeline."
          )}
          banner
          closable
          onClose={() => {
            localStorage.closedAutomatedStageMessageInfo = "true";
          }}
        />
      )}

      <div className="px-4 py-6 sm:p-8">
        <div className="mt-2">
          {softValue?.messagingAutomation?.map?.((messageTemplate, index) => (
            <div key={index}>
              <div className="space-x-2">
                <div>
                  <div className="flex justify-between">
                    <label className="font-bold">{t("Subject")}</label>
                    <FaDeleteLeft
                      size={25}
                      title={t("Delete")}
                      color="#333"
                      className="cursor-pointer"
                      onClick={() =>
                        setSoftValue((v) => ({
                          ...v,
                          messagingAutomation: removeAtIndex(
                            v.messagingAutomation,
                            index
                          ),
                        }))
                      }
                    />
                  </div>

                  <MentionsInput
                    placeholder={t("Type # to browse variables")}
                    value={messageTemplate.subject}
                    onChange={(_, value) => {
                      setSoftValue((v) => ({
                        ...v,
                        messagingAutomation: replaceAtIndex(
                          v.messagingAutomation,
                          index,
                          {
                            subject: value,
                            body: v.messagingAutomation[index].body,
                            hours: v.messagingAutomation[index].hours,
                          }
                        ),
                      }));
                    }}
                    classNames={classNames}
                    a11ySuggestionsListLabel={t("Possible variables")}
                  >
                    <Mention
                      trigger="#"
                      className={"bg-indigo-100"}
                      data={[
                        {
                          id: "candidateFirstname",
                          display: t("Lead's Firstname"),
                        },
                        {
                          id: "candidateLastname",
                          display: t("Lead's Lastname"),
                        },
                        { id: "candidateEmail", display: t("Lead's Email") },
                        { id: "candidatePhone", display: t("Lead's Phone") },
                        { id: "jobTitle", display: t("Job Title") },
                        { id: "jobLocation", display: t("Job Location") },
                        {
                          id: "jobApplicationLink",
                          display: t("Job Application Link"),
                        },
                        { id: "interviewDate", display: t("Interview Date") },
                        { id: "interviewTime", display: t("Interview Time") },
                        {
                          id: "interviewDateEnd",
                          display: t("Interview Ending Date"),
                        },
                        {
                          id: "interviewTimeEnd",
                          display: t("Interview Ending Time"),
                        },
                        {
                          id: "interviewMeetingLink",
                          display: t("Interview Meeting Link"),
                        },
                        {
                          id: "calendly_cancel_url",
                          display: t("Calendly Cancel URL"),
                        },
                        {
                          id: "calendly_reschedule_url",
                          display: t("Calendly Reschedule URL"),
                        },
                        { id: "companyName", display: t("Company Name") },
                        { id: "companyWebsite", display: t("Company Website") },
                        { id: "companyAddress", display: t("Company Address") },
                        {
                          id: "applicationStatus",
                          display: t("Application Status"),
                        },
                        {
                          id: "rejectionReason",
                          display: t("Rejection Reason"),
                        },
                        { id: "calendarLink", display: t("Calendar Link") },
                        {
                          id: "userCalendarLink",
                          display: t("User Calendar Link"),
                        },
                        { id: "currentDate", display: t("Current Date") },
                        { id: "currentTime", display: t("Current Time") },
                        { id: "userFirstname", display: t("User Firstname") },
                        { id: "userLastname", display: t("User Lastname") },
                        { id: "userEmail", display: t("User Email") },
                        { id: "userPhone", display: t("User Phone") },
                        { id: "cvLink", display: t("CV Submission Link") },
                        { id: "surveyLink", display: t("Survey Link") },
                        {
                          id: "interviewTimeRemaining",
                          display: t("Interview Remaining Time"),
                        },
                      ]}
                    />
                  </MentionsInput>
                </div>

                <div>
                  <label className="font-bold">{t("Body")}</label>
                  <MentionsInput
                    placeholder={t("Type # to browse variables")}
                    value={messageTemplate.body}
                    onChange={(_, value) => {
                      setSoftValue((v) => ({
                        ...v,
                        messagingAutomation: replaceAtIndex(
                          v.messagingAutomation,
                          index,
                          {
                            subject: v.messagingAutomation[index].subject,
                            hours: v.messagingAutomation[index].hours,
                            body: value,
                          }
                        ),
                      }));
                    }}
                    classNames={classNamesBody}
                    a11ySuggestionsListLabel={t("Possible variables")}
                  >
                    <Mention
                      trigger="#"
                      className={"bg-indigo-100"}
                      data={[
                        {
                          id: "candidateFirstname",
                          display: t("Lead's Firstname"),
                        },
                        {
                          id: "candidateLastname",
                          display: t("Lead's Lastname"),
                        },
                        { id: "candidateEmail", display: t("Lead's Email") },
                        { id: "candidatePhone", display: t("Lead's Phone") },
                        { id: "jobTitle", display: t("Job Title") },
                        { id: "jobLocation", display: t("Job Location") },
                        {
                          id: "jobApplicationLink",
                          display: t("Job Application Link"),
                        },
                        { id: "interviewDate", display: t("Interview Date") },
                        { id: "interviewTime", display: t("Interview Time") },
                        {
                          id: "interviewDateEnd",
                          display: t("Interview Ending Date"),
                        },
                        {
                          id: "interviewTimeEnd",
                          display: t("Interview Ending Time"),
                        },
                        {
                          id: "interviewMeetingLink",
                          display: t("Interview Meeting Link"),
                        },
                        {
                          id: "calendly_cancel_url",
                          display: t("Calendly Cancel URL"),
                        },
                        {
                          id: "calendly_reschedule_url",
                          display: t("Calendly Reschedule URL"),
                        },
                        { id: "companyName", display: t("Company Name") },
                        { id: "companyWebsite", display: t("Company Website") },
                        { id: "companyAddress", display: t("Company Address") },
                        {
                          id: "applicationStatus",
                          display: t("Application Status"),
                        },
                        {
                          id: "rejectionReason",
                          display: t("Rejection Reason"),
                        },
                        { id: "calendarLink", display: t("Calendar Link") },
                        {
                          id: "userCalendarLink",
                          display: t("User Calendar Link"),
                        },
                        { id: "currentDate", display: t("Current Date") },
                        { id: "currentTime", display: t("Current Time") },
                        { id: "userFirstname", display: t("User Firstname") },
                        { id: "userLastname", display: t("User Lastname") },
                        { id: "userEmail", display: t("User Email") },
                        { id: "userPhone", display: t("User Phone") },
                        { id: "cvLink", display: t("CV Submission Link") },
                        { id: "surveyLink", display: t("Survey Link") },
                        {
                          id: "interviewTimeRemaining",
                          display: t("Interview Remaining Time"),
                        },
                      ]}
                    />
                  </MentionsInput>
                </div>

                <Space>
                  <label className="font-bold">{t("Delay")}</label>
                  <Tooltip
                    title={t(
                      "This message will be sent {{hours}} hour(s) after lead stays idle in this stage.",
                      { hours: messageTemplate?.hours ?? 0 }
                    )}
                  >
                    <GrInfo />
                  </Tooltip>
                </Space>
                <div>
                  <InputNumber
                    value={messageTemplate.hours}
                    onChange={(value) => {
                      setSoftValue((v) => ({
                        ...v,
                        messagingAutomation: replaceAtIndex(
                          v.messagingAutomation,
                          index,
                          {
                            subject: v.messagingAutomation[index].subject,
                            hours: value,
                            body: v.messagingAutomation[index].body,
                          }
                        ),
                      }));
                    }}
                  />
                </div>

                <Divider />
              </div>
            </div>
          ))}
        </div>

        <button
          className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
          onClick={(e) => {
            setSoftValue((v) => ({
              ...v,
              messagingAutomation: [
                ...v.messagingAutomation,
                { subject: "", body: "", hours: 0 },
              ],
            }));
          }}
        >
          {t("+ Add Message")}
        </button>
      </div>

      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
        <button
          onClick={handleUpdate}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {t("Save")}
        </button>
      </div>
    </>
  );
};

export default DelayedAutomations;
