import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Alert,
  Button,
  Card,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Spin,
  Switch,
  message,
} from "antd";
import moment from "moment";
import { FaRedo, FaUndo } from "react-icons/fa";
import { TbRobotFace } from "react-icons/tb";
import { Mention, MentionsInput } from "react-mentions";
import { useSelector } from "react-redux";
import {
  MINIMUM_AI_CHARS,
  STANDARD_MOMENT_FORMAT,
} from "../../../data/constants";
import { getPartner } from "../../../redux/auth/selectors";
import CrudService from "../../../service/CrudService";
import MessagingService from "../../../service/MessagingService";
import classNamesBody from "./body.module.css";
import classNames from "./example.module.css";
import useHistory from "./useHistory";

const correctText = (text) =>
  text
    .replace(/\[Lead's Firstname\]/g, "@[Lead's Firstname](candidateFirstname)")
    .replace(/\[Lead's Lastname\]/g, "@[Lead's Lastname](candidateLastname)")
    .replace(/\[Lead's Email\]/g, "@[Lead's Email](candidateEmail)")
    .replace(/\[Lead's Phone\]/g, "@[Lead's Phone](candidatePhone)")
    .replace(/\[Job Title\]/g, "@[Job Title](jobTitle)")
    .replace(/\[Job Location\]/g, "@[Job Location](jobLocation)")
    .replace(
      /\[Job Application Link\]/g,
      "@[Job Application Link](jobApplicationLink)"
    )
    .replace(/\[Interview Date\]/g, "@[Interview Date](interviewDate)")
    .replace(/\[Interview Time\]/g, "@[Interview Time](interviewTime)")
    .replace(
      /\[Interview Ending Date\]/g,
      "@[Interview Ending Date](interviewDateEnd)"
    )
    .replace(
      /\[Interview Ending Time\]/g,
      "@[Interview Ending Time](interviewTimeEnd)"
    )
    .replace(
      /\[Interview Meeting Link\]/g,
      "@[Interview Meeting Link](interviewMeetingLink)"
    )
    .replace(
      /\[Calendly Cancel URL\]/g,
      "@[Calendly Cancel URL](calendly_cancel_url)"
    )
    .replace(
      /\[Calendly Reschedule URL\]/g,
      "@[Calendly Reschedule URL](calendly_reschedule_url)"
    )
    .replace(/\[Company Name\]/g, "@[Company Name](companyName)")
    .replace(/\[Company Website\]/g, "@[Company Website](companyWebsite)")
    .replace(/\[Company Address\]/g, "@[Company Address](companyAddress)")
    .replace(
      /\[Application Status\]/g,
      "@[Application Status](applicationStatus)"
    )
    .replace(/\[Rejection Reason\]/g, "@[Rejection Reason](rejectionReason)")
    .replace(/\[Calendar Link\]/g, "@[Calendar Link](calendarLink)")
    .replace(/\[Current Date\]/g, "@[Current Date](currentDate)")
    .replace(/\[Current Time\]/g, "@[Current Time](currentTime)")
    .replace(/\[User Firstname\]/g, "@[User Firstname](userFirstname)")
    .replace(/\[User Lastname\]/g, "@[User Lastname](userLastname)")
    .replace(/\[User Email\]/g, "@[User Email](userEmail)")
    .replace(/\[User Phone\]/g, "@[User Phone](userPhone)")
    .replace(/\[CV Submission Link\]/g, "@[CV Submission Link](cvLink)")
    .replace(/\[Survey Link\]/g, "@[Survey Link](surveyLink)")
    .replace(
      /\[Interview Remaining Time\]/g,
      "@[Interview Remaining Time](interviewTimeRemaining)"
    );

const ScheduledMeeting = ({ candidateData, close }) => {
  const { t } = useTranslation();
  const {
    interviewMeetingTimezone,
    calendly_cancel_url,
    calendly_reschedule_url,
    interviewMeetingTimestamp,
    interviewMeetingTimestampEnd,
  } = candidateData;

  const openCalendlyCancelUrl = () => {
    window.open(calendly_cancel_url, "_blank");
    close();
  };

  const openCalendlyRescheduleUrl = () => {
    window.open(calendly_reschedule_url, "_blank");
    close();
  };

  return (
    <Card
      title={t("Scheduled Meeting Details")}
      className="bg-white dark:bg-gray-900 rounded-lg shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  mx-[-24px] my-[-30px]"
    >
      <p>
        <strong>{t("Meeting Start Time")}:</strong>{" "}
        {moment(interviewMeetingTimestamp).format("HH:mm, Do MMM, YYYY")}
      </p>
      <p className="mb-5">
        <strong>{t("Meeting End Time")}:</strong>{" "}
        {moment(interviewMeetingTimestampEnd).format("HH:mm, Do MMM, YYYY")}
      </p>
      {candidateData?.interviewMeetingLink && (
        <a
          className="mr-2 px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          href={candidateData?.interviewMeetingLink}
          target="_blank"
        >
          {t("Join Meeting")}
        </a>
      )}

      {/* Buttons to reschedule or cancel the event */}
      <div className="mt-8">
        <button
          className="mr-2 px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={openCalendlyRescheduleUrl}
        >
          {t("Reschedule Event")}
        </button>
        <button
          className="px-2 py-1 text-sm bg-red-500 text-white rounded"
          onClick={openCalendlyCancelUrl}
        >
          {t("Cancel Event")}
        </button>
      </div>
    </Card>
  );
};

const CandidateMeetingBox = ({
  candidateId,
  onSend,
  workflowData,
  defaultSubject,
  defaultBody,
  close,
}) => {
  const { t } = useTranslation();
  const [subject, setSubject] = useState(
    defaultSubject ?? localStorage["lastMeetingMessage_subject"] ?? ""
  );
  const {
    state: body,
    setState: setBody,
    undo: undoBody,
    redo: redoBody,
  } = useHistory(
    defaultBody ?? localStorage["lastMeetingMessage_message"] ?? ""
  );
  const [includeBCC, setIncludeBcc] = useState(
    localStorage["lastMeetingMessage_includeBCC"] === "true"
  );
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [AILoading, setAILoading] = useState(false);
  const [candidateData, setCandidateData] = useState(null);
  const socket = useRef(null);
  const socketPing = useRef(null);
  const partner = useSelector(getPartner);

  const handleReset = () => {
    onSend(subject, body);
    setSubject("");
    setBody("");
    setIncludeBcc(false);
  };

  const reloadTemplates = useCallback(async () => {
    await CrudService.search("MessageTemplate", 10000000, 1, {
      sort: { createdAt: 1 },
    }).then(({ data }) => {
      setTemplates(data.items);
    });
  }, []);

  useEffect(() => {
    reloadTemplates();
  }, [reloadTemplates]);

  useEffect(() => {
    const selected = templates.find((te) => te._id === selectedTemplate);
    if (selected) {
      setSubject(selected.subject);
      setBody(selected.message);
      setIncludeBcc(selected.includeBCC);
    }
  }, [selectedTemplate, templates]);

  useEffect(() => {
    if (!candidateId) return;
    CrudService.getSingle("VacancySubmission", candidateId).then(({ data }) => {
      if (data) setCandidateData(data);
    });
  }, [candidateId]);

  if (
    candidateData?.meetingScheduled &&
    new Date(candidateData?.interviewMeetingTimestampEnd) > new Date()
  )
    return <ScheduledMeeting candidateData={candidateData} close={close} />;
  if (!candidateData) return <Skeleton active />;
  return (
    <>
      <label className="font-bold">{t("Template")}</label>
      <div className="w-full flex items-center">
        <Select
          className="grow"
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e)}
          showSearch
          filterOption={(input, option) => {
            // Filter based on the label (template.subject)
            const label = option.label
              .replace(/\@\[/g, "")
              .replace(/\]$$(.)*$$/g, "")
              .toLowerCase();
            return label.includes(input.toLowerCase());
          }}
        >
          {templates.map((te) => (
            <Select.Option
              key={te._id}
              value={te._id}
              label={
                te.subject
                  .replace(/\@\[/g, "")
                  .replace(/\]$$(.)*$$/g, "")
                  .slice(0, 40) || "-"
              }
            >
              <Space className="flex justify-between">
                <div>
                  {te.subject
                    .replace(/\@\[/g, "")
                    .replace(/\]$$(.)*$$/g, "")
                    .slice(0, 40) || "-"}
                </div>
                <div>
                  <Popconfirm
                    title={t("Sure to delete?")}
                    onConfirm={async () => {
                      setLoading(true);
                      try {
                        await CrudService.delete("MessageTemplate", te._id);
                        await reloadTemplates();
                        setSelectedTemplate(null);
                      } catch (e) {
                      } finally {
                        setLoading(false);
                      }
                    }}
                  >
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      size="small"
                      danger
                      disabled={loading}
                    >
                      {t("Delete")}
                    </Button>
                  </Popconfirm>
                </div>
              </Space>
            </Select.Option>
          ))}
        </Select>
        <button
          onClick={async () => {
            if (!subject)
              return message.error(t("Please enter a subject line"));
            if (!body) return message.error(t("Message cannot be empty"));

            setLoading(true);
            try {
              const current = await CrudService.create("MessageTemplate", {
                subject: subject,
                message: body,
                includeBCC,
              });
              await reloadTemplates();
              if (current?.data?.result?._id)
                setSelectedTemplate(current?.data?.result?._id);
            } catch (e) {
            } finally {
              setLoading(false);
            }
          }}
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          disabled={loading}
        >
          {!loading ? t("Save Current") : <Spin>{t("Save Current")}</Spin>}
        </button>
      </div>

      <br />

      {candidateData?.cancelledMeeting && (
        <Alert
          type="warning"
          message={t(
            "The meeting previously arranged has been cancelled{{cancelAt}}{{cancelledBy}}{{cancelReason}}",
            {
              cancelAt: candidateData?.cancelAt
                ? " at " +
                  moment(candidateData?.cancelAt).format(STANDARD_MOMENT_FORMAT)
                : "",
              cancelledBy: candidateData?.cancelledBy
                ? " by " + candidateData?.cancelledBy
                : "",
              cancelReason: candidateData?.cancelReason
                ? ". Reason: " + candidateData?.cancelReason
                : ".",
            }
          )}
        />
      )}

      <br />
      <label className="font-bold">{t("Recipient")}</label>
      {candidateData?.formData && (
        <div>
          {candidateData?.formData?.firstname}{" "}
          {candidateData?.formData?.lastname}{" "}
          <a
            href={`mailto:${candidateData?.formData?.email}`}
          >{`<${candidateData?.formData?.email}>`}</a>
        </div>
      )}

      <br />
      {!workflowData && (
        <div>
          <Space size={20}>
            <label className="font-bold">{t("Include me in BCC")}</label>
            <Switch checked={includeBCC} onChange={(e) => setIncludeBcc(e)} />
          </Space>
        </div>
      )}

      <br />
      <label className="font-bold">{t("Subject")}</label>
      <MentionsInput
        placeholder={t("Type # to browse variables")}
        value={subject}
        onChange={(_, value) => setSubject(value)}
        classNames={classNames}
        a11ySuggestionsListLabel={t("Possible variables")}
      >
        <Mention
          trigger="#"
          className={"bg-indigo-100"}
          data={[
            { id: "candidateFirstname", display: t("Lead's Firstname") },
            { id: "candidateLastname", display: t("Lead's Lastname") },
            { id: "candidateEmail", display: t("Lead's Email") },
            { id: "candidatePhone", display: t("Lead's Phone") },
            { id: "jobTitle", display: t("Job Title") },
            { id: "jobLocation", display: t("Job Location") },
            { id: "jobApplicationLink", display: t("Job Application Link") },
            { id: "interviewDate", display: t("Interview Date") },
            { id: "interviewTime", display: t("Interview Time") },
            { id: "interviewDateEnd", display: t("Interview Ending Date") },
            { id: "interviewTimeEnd", display: t("Interview Ending Time") },
            {
              id: "interviewMeetingLink",
              display: t("Interview Meeting Link"),
            },
            { id: "calendly_cancel_url", display: t("Calendly Cancel URL") },
            {
              id: "calendly_reschedule_url",
              display: t("Calendly Reschedule URL"),
            },
            { id: "companyName", display: t("Company Name") },
            { id: "companyWebsite", display: t("Company Website") },
            { id: "companyAddress", display: t("Company Address") },
            { id: "applicationStatus", display: t("Application Status") },
            { id: "rejectionReason", display: t("Rejection Reason") },
            { id: "calendarLink", display: t("Calendar Link") },
            { id: "userCalendarLink", display: t("User Calendar Link") },
            { id: "currentDate", display: t("Current Date") },
            { id: "currentTime", display: t("Current Time") },
            { id: "userFirstname", display: t("User Firstname") },
            { id: "userLastname", display: t("User Lastname") },
            { id: "userEmail", display: t("User Email") },
            { id: "userPhone", display: t("User Phone") },
            { id: "cvLink", display: t("CV Submission Link") },
            { id: "surveyLink", display: t("Survey Link") },
            {
              id: "interviewTimeRemaining",
              display: t("Interview Remaining Time"),
            },
          ]}
        />
      </MentionsInput>

      <br />

      <div className="flex justify-between">
        <label className="font-bold">{t("Message")}</label>

        <Space>
          <button
            className="px-2 py-1 text-sm bg-white-500 text-indigo-500 border border-indigo-500 rounded"
            onClick={undoBody}
            type="secondary"
          >
            <FaUndo />
          </button>
          <button
            className="px-2 py-1 text-sm bg-white-500 text-indigo-500 border border-indigo-500 rounded"
            onClick={redoBody}
            type="secondary"
          >
            <FaRedo />
          </button>
          {AILoading && <Spin />}

          <TbRobotFace
            loading={AILoading}
            size={18}
            className="cursor-pointer"
            onClick={() => {
              if (AILoading) return;
              if (!body) return message.info(t("Please write some text first"));
              if (body?.length < MINIMUM_AI_CHARS)
                return message.info(
                  t(
                    "AI needs a little more context. Please write at least {{count}} characters.",
                    { count: MINIMUM_AI_CHARS }
                  )
                );

              socket.current = new WebSocket(
                `wss://booklified-chat-socket.herokuapp.com`
              );

              socket.current.addEventListener("open", async () => {
                socketPing.current = setInterval(
                  () => socket.current.send(JSON.stringify({ id: "PING" })),
                  30000
                );
                const content = `Hello, I need your expertise in transforming the following email text into a highly professional version. Please apply your literary skills to rewrite this text. Elevate its language, make it more engaging to read. Here's the text:

                        ${body}

                        ________
                        You need to use the following structure for variables: [Lead's Firstname]

                        Here is a list of specified variables:
                        [
                          { id: "candidateFirstname", display: "Lead's Firstname" },
                          { id: "candidateLastname", display: "Lead's Lastname" },
                          { id: "candidateEmail", display: "Lead's Email" },
                          { id: "candidatePhone", display: "Lead's Phone" },
                          { id: "jobTitle", display: "Job Title" },
                          { id: "jobLocation", display: "Job Location" },
                          { id: "jobApplicationLink", display: "Job Application Link" },
                          { id: "interviewDate", display: "Interview Date" },
                          { id: "interviewTime", display: "Interview Time" },
                          { id: "interviewDateEnd", display: "Interview Ending Date" },
                          { id: "interviewTimeEnd", display: "Interview Ending Time" },
                          { id: "interviewMeetingLink", display: "Interview Meeting Link" },
                          { id: "calendly_cancel_url", display: "Calendly Cancel URL" },
                          { id: "calendly_reschedule_url", display: "Calendly Reschedule URL" },
                          { id: "companyName", display: "Company Name" },
                          { id: "companyWebsite", display: "Company Website" },
                          { id: "companyAddress", display: "Company Address" },
                          { id: "applicationStatus", display: "Application Status" },
                          { id: "rejectionReason", display: "Rejection Reason" },
                          { id: "calendarLink", display: "Calendar Link" },
                          { id: "userCalendarLink", display: "User Calendar Link" },
                          { id: "currentDate", display: "Current Date" },
                          { id: "currentTime", display: "Current Time" },
                          { id: "userFirstname", display: "User Firstname" },
                          { id: "userLastname", display: "User Lastname" },
                          { id: "userEmail", display: "User Email" },
                          { id: "userPhone", display: "User Phone" },
                          { id: "cvLink", display: "CV Submission Link" },
                          { id: "surveyLink", display: "Survey Link" },
                          {
                            id: "interviewTimeRemaining",
                            display: "Interview Remaining Time",
                          },
                        ]

                        It is imperative that you DO NOT use any variables other than the ones specified.

                        It is imperative to use the exact format as it is written here. Here are some possible examples of how a variable can look like inside your text:
                        [Current Time]
                        [Company Name]
                        [Job Location]
                        [Lead's Phone]
                        [Lead's Email]

                        It is imperative that your reply contains nothing beyond the upgraded text. Please only answer with the enhanced version of the text. Do not add anything else into your answer.
                        `;

                setAILoading(true);
                socket.current.send(
                  JSON.stringify({
                    id: "OPEN_AI_PROMPT",
                    payload: {
                      content,
                      model: "gpt-3.5-turbo",
                      partner: partner._id,
                    },
                  })
                );
              });

              socket.current.addEventListener("message", async (event) => {
                const message = JSON.parse(event.data);
                const response = message.payload?.response;

                setBody(correctText(response));
                setAILoading(false);
                if (socketPing.current) clearInterval(socketPing.current);
              });
            }}
          />
        </Space>
      </div>
      <MentionsInput
        placeholder={t("Type # to browse variables")}
        value={body}
        onChange={(_, value) => setBody(value)}
        classNames={classNamesBody}
        a11ySuggestionsListLabel={t("Possible variables")}
      >
        <Mention
          trigger="#"
          className={"bg-indigo-100"}
          data={[
            { id: "candidateFirstname", display: t("Lead's Firstname") },
            { id: "candidateLastname", display: t("Lead's Lastname") },
            { id: "candidateEmail", display: t("Lead's Email") },
            { id: "candidatePhone", display: t("Lead's Phone") },
            { id: "jobTitle", display: t("Job Title") },
            { id: "jobLocation", display: t("Job Location") },
            { id: "jobApplicationLink", display: t("Job Application Link") },
            { id: "interviewDate", display: t("Interview Date") },
            { id: "interviewTime", display: t("Interview Time") },
            { id: "interviewDateEnd", display: t("Interview Ending Date") },
            { id: "interviewTimeEnd", display: t("Interview Ending Time") },
            {
              id: "interviewMeetingLink",
              display: t("Interview Meeting Link"),
            },
            { id: "calendly_cancel_url", display: t("Calendly Cancel URL") },
            {
              id: "calendly_reschedule_url",
              display: t("Calendly Reschedule URL"),
            },
            { id: "companyName", display: t("Company Name") },
            { id: "companyWebsite", display: t("Company Website") },
            { id: "companyAddress", display: t("Company Address") },
            { id: "applicationStatus", display: t("Application Status") },
            { id: "rejectionReason", display: t("Rejection Reason") },
            { id: "calendarLink", display: t("Calendar Link") },
            { id: "userCalendarLink", display: t("User Calendar Link") },
            { id: "currentDate", display: t("Current Date") },
            { id: "currentTime", display: t("Current Time") },
            { id: "userFirstname", display: t("User Firstname") },
            { id: "userLastname", display: t("User Lastname") },
            { id: "userEmail", display: t("User Email") },
            { id: "userPhone", display: t("User Phone") },
            { id: "cvLink", display: t("CV Submission Link") },
            { id: "surveyLink", display: t("Survey Link") },
            {
              id: "interviewTimeRemaining",
              display: t("Interview Remaining Time"),
            },
          ]}
        />
      </MentionsInput>

      {!localStorage?.informationMessageVariables001 && (
        <Alert
          type="info"
          message={t(
            "To explore available messaging variables, simply type '#' followed by your keyword, and a list of suggestions will appear for your convenience."
          )}
          closable
          onClose={() => (localStorage.informationMessageVariables001 = "true")}
        />
      )}

      <br />

      <div className="flex items-center justify-between">
        <div />
        <button
          onClick={async () => {
            if (!subject)
              return message.error(t("Please enter a subject line"));
            if (!body) return message.error(t("Message cannot be empty"));

            if (!body.includes("@[Calendar Link](calendarLink)"))
              return message.error(
                t("Message must include the variable Calendar Link")
              );

            setLoading(true);
            try {
              if (!workflowData && candidateId)
                await MessagingService.messageCandidate({
                  candidateId,
                  subject: subject,
                  message: body,
                  includeBCC,
                });
              handleReset();
              localStorage["lastMeetingMessage_subject"] = subject;
              localStorage["lastMeetingMessage_message"] = body;
              localStorage["lastMeetingMessage_includeBCC"] = includeBCC;
            } catch (e) {
            } finally {
              setLoading(false);
            }
          }}
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded mt-5"
          disabled={loading}
        >
          {!loading ? (
            t(workflowData ? "Save" : "Send")
          ) : (
            <Spin>{t(workflowData ? "Save" : "Send")}</Spin>
          )}
        </button>
      </div>
    </>
  );
};

export default CandidateMeetingBox;
